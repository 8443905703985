import { motion } from "framer-motion";
import React, { ReactNode } from "react";
interface LaunchText {
  text: ReactNode;
  wings: Boolean;
}

const Launch: React.FC<LaunchText> = ({ text, wings }) => {
  return (
    <motion.div
      className="flex justify-center w-fit items-center px-4 h-[61px] rounded-full text-sm md:text-lg text-[white] font-normal launch relative"
      style={{ border: "4px solid white" }}
      animate={{
        x: [0, -2, 2, 0], // Define the keyframes for horizontal movement
        rotate: [0, -1, 1, 0], // Define the keyframes for rotation
      }}
      transition={{
        duration: 0.2, // The duration for each cycle of the animation
        ease: "easeInOut",
        repeat: Infinity, // Repeat the animation infinitely
      }}
    >
      {wings && (
        <img
          src="/images/button-wing-1.svg"
          alt="wing"
          className="absolute -left-10 -top-5 h-[46px]"
        />
      )}
      {text}
      {wings && (
        <img
          src="/images/button-wing-2.svg"
          alt="wing"
          className="absolute h-[46px] -right-10"
        />
      )}
    </motion.div>
  );
};

export default Launch;
