import { motion, useInView } from "framer-motion";
import { useRef } from "react";

const Footer = () => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const animationVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, scale: [1, 1.1, 1] },
  };
  return (
    <div className="flex flex-col gap-8 footer">
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col pt-11 md:pt-40 md:pb-20 gap-4 md:gap-5 px-10 md:pl-[69px] md:px-0">
          <div className="text-3xl md:text-5xl footer-header text-white">
            Find Us
          </div>
          <div className="flex flex-col md:flex-row gap-4">
            <motion.a
              href="https://t.me/SLAV_Coin"
              target="_blank"
              rel="noreferrer"
              ref={ref}
              animate={inView ? "visible" : "hidden"}
              variants={animationVariants}
              transition={{ duration: 0.6 }}
              className="flex flex-row gap-2 p-2 w-[289px] md:w-[313px] items-center bg-[#FFCB1A] text-black footer-link"
            >
              <img
                src="/images/telegram-2.svg"
                alt="tele"
                className="w-[40px]"
              />
              <span className="text-base">join telegram</span>
            </motion.a>
            <motion.a
              href="https://x.com/SLAV_Coin"
              target="_blank"
              rel="noreferrer"
              ref={ref}
              animate={inView ? "visible" : "hidden"}
              variants={animationVariants}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="flex flex-row gap-2 p-2 w-[289px] md:w-[313px] items-center bg-[#FFCB1A] text-black footer-link"
            >
              <img src="/images/x-2.svg" alt="tele" className="w-[40px]" />
              <span className="text-base">Follow x</span>
            </motion.a>
          </div>
          <motion.div
            ref={ref}
            animate={inView ? "visible" : "hidden"}
            variants={animationVariants}
            transition={{ duration: 0.6, delay: 0.6 }}
            className="flex flex-col gap-4 md:gap-6 text-white footer-header"
          >
            <div className="text-3xl md:text-5xl">
              Want to Contribute or Get some $Slav ?
            </div>
            <div className="flex flex-row gap-2 items-center text-2xl">
              <div>Email:</div>
              <a href="mailto:SLAV@SLAV.MEME" className="text-[#2BA500]">
                SLAV@SLAV.MEME
              </a>
            </div>
            {/* <div className="flex flex-row gap-2 items-center text-2xl">
              <div>Business:</div>
              <a href="mailto:Business@Bictory.io" className="text-[#2BA500]">
                Business@Bictory.io
              </a>
            </div> */}
          </motion.div>
        </div>
        <motion.img
          ref={ref}
          animate={inView ? "visible" : "hidden"}
          variants={animationVariants}
          transition={{ duration: 0.6, delay: 0.6 }}
          src="/images/footer-squat.png"
          alt="slav"
          className="w-[393px] md:w-[435px] h-fit md:pt-6"
        />
      </div>
      <div className="flex justify-center footer-header md:justify-end w-full text-lg text-white opacity-60 pb-4 md:pb-10 md:pr-10">
        All Rights are stolen by Gopniks. 2049
      </div>
    </div>
  );
};

export default Footer;
