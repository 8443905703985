import React from "react";

interface NotificationProps {
  closeBanner: () => void;
}

const Notification: React.FC<NotificationProps> = ({ closeBanner }) => {
  return (
    <div className="w-full absolute top-0 bg-[#2BA500] py-2 justify-between flex md:px-6 flex-row px-2 text-white font-semibold z-50">
      <div className="w-1 hidden md:block"></div>
      <span>COMMUNITY TAKEOVER ---&gt; CTO</span>
      <button onClick={closeBanner}>X</button>
    </div>
  );
};

export default Notification;
