import { useRef } from "react";
import { motion, useInView } from "framer-motion";

const Tokenomics = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true }); // Adjust threshold as needed

  // Animation variants
  const variants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  return (
    <div className="flex flex-col relative tokenomics pt-20 md:pt-11 items-center md:pb-44 pb-8">
      <div className="flex flex-row justify-center">
        <motion.img
          src="/images/squatting-slavs-1.png"
          alt="slav"
          className="absolute w-[101px] md:w-[170px] left-0 md:top-5 top-1"
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={variants}
          ref={ref}
        />
        <div className="relative w-[383px] md:w-[737px] z-10" ref={ref}>
          <motion.img
            src="/images/tokenomics-header.png"
            alt="header"
            className="w-full"
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={variants}
          />
        </div>
        <motion.img
          src="/images/squatting-slavs-2.png"
          alt="slav"
          className="absolute w-[100px] md:w-[212px] right-0 top-1"
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={variants}
        />
      </div>
      <div className="flex flex-row relative justify-center w-full">
        <motion.img
          src="/images/squatting-slavs-3.png"
          alt="squat"
          className="h-[111px] md:h-[302px] absolute z-10 -bottom-8 md:-bottom-36 left-2 md:left-96"
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={variants}
        />
        <motion.img
          src="/images/tokenomics.png"
          className="w-[543px] z-0 relative -mt-10 md:-mt-32 hidden md:block"
          alt=""
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={variants}
        />
        <motion.img
          src="/images/tokenomics-mobile.png"
          className="w-[337px] z-0 relative -mt-20 md:-mt-32 block md:hidden"
          alt=""
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={variants}
        />
        <motion.img
          src="/images/destination.png"
          className="md:h-[278px] h-[159.364px] absolute md:right-96 z-10 -bottom-8 md:-bottom-28"
          alt=""
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={variants}
        />
        <motion.img
          src="/images/squatting-slavs-4.png"
          className="md:h-[278px] h-[139.364px] absolute z-10 -bottom-8 md:bottom-0 right-0"
          alt=""
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={variants}
        />
      </div>
    </div>
  );
};

export default Tokenomics;
