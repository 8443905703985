import React, { useRef } from "react";
import { useInView, motion } from "framer-motion";
import { roadmapContent } from "../constants";

const Roadmap = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animationVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, scale: [1, 1.1, 1] },
  };
  return (
    <div className="showcase flex flex-col justify-center items-center pt-16 md:pt-28 pb-6 md:pb-[180px]">
      <motion.h1
        ref={ref}
        className="text-[32px] md:text-[81px] text-white showcase-heading"
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={animationVariants}
        transition={{ duration: 0.4, delay: 0.2 }}
      >
        RoadFuckingMap
      </motion.h1>
      <div className="flex flex-wrap justify-center gap-0 mt-5 md:mt-8 md:gap-5 px-4">
        {roadmapContent.map((item, index) => (
          <motion.div
            key={index}
            ref={ref}
            className="bg-[#1a1a1a] rounded-lg shadow-lg p-6 w-full max-w-md mb-8"
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={animationVariants}
            transition={{ duration: 0.4, delay: 0.1 * index }}
          >
            <h3 className="text-white text-xl md:text-2xl font-bold mb-4">
              {item.heading}
            </h3>
            <ul className="list-disc pl-6 text-white text-sm md:text-base">
              {item.points.map((point, pointIndex) => (
                <motion.li
                  key={pointIndex}
                  initial="hidden"
                  animate={isInView ? "visible" : "hidden"}
                  variants={animationVariants}
                  transition={{
                    duration: 0.4,
                  }}
                >
                  {point}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
