import { motion, AnimatePresence } from "framer-motion";
import Launch from "./components/Launch";
import { useState } from "react";

const Hero = () => {
  // const [showModal, setShowModal] = useState(false);

  // const handleClick = () => {
  //   setShowModal(true);
  // };

  // const closeModal = (e: React.MouseEvent<HTMLDivElement>) => {
  //   if (e.target === e.currentTarget) {
  //     setShowModal(false);
  //   }
  // };

  return (
    <div className="flex flex-col hero overflow-hidden relative">
      <div className="text-center flex flex-col items-center w-full text-white mt-[120px] md:mt-[66px]">
        <motion.img
          src="/images/CTO.png"
          alt="cto"
          className="absolute w-32 z-0 top-12 left-5 md:w-56 md:left-10 md:top-72"
          animate={{
            x: [-10, 10, -10], // Define the keyframes for horizontal movement
            rotate: [-8, 8, -8], // Define the keyframes for rotation
          }}
          transition={{
            duration: 1, // Increase duration for smoother motion
            ease: "easeInOut", // Smooth transitions
            repeat: Infinity, // Repeat the animation infinitely
          }}
        />
        <motion.img
          src="/images/CTO.png"
          alt="cto"
          className="absolute w-32 z-0 top-56 right-5 md:w-56 md:right-10 md:top-24"
          animate={{
            x: [-14, 14, -14], // Define the keyframes for horizontal movement
            rotate: [-8, 8, -8], // Define the keyframes for rotation
          }}
          transition={{
            duration: 1, // Increase duration for smoother motion
            ease: "easeInOut", // Smooth transitions
            repeat: Infinity, // Repeat the animation infinitely
          }}
        />

        <motion.div
          className="hero-heading text-5xl md:text-[120px] z-10"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          SLAV Coin
        </motion.div>
        <div className="flex flex-row gap-2 items-center justify-center z-10">
          <motion.div
            className="hero-subheading font-normal text-base md:text-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Deflationary Cryptocurrency
          </motion.div>
          {/* <motion.img
            src="/images/bnb.png"
            className="h-4 md:h-5"
            alt="bnb"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          /> */}
        </div>
        <div className="flex flex-wrap pt-4 items-center w-full justify-center gap-4 px-2 md:justify-center md:gap-10 z-10">
          <a
            href="https://coinmarketcap.com/currencies/slav/"
            target="_blank"
            rel="noreferrer"
          >
            <motion.img
              src="/images/coinmarketcap-seeklogo.png"
              className="h-4 md:h-9"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            />
          </a>
          <a
            href="https://www.flap.sh/0x5cc3de2a7f03b1c0a5661a846b367460c3bd128f"
            target="_blank"
            rel="noreferrer"
          >
            <motion.img
              src="/images/flap-2.png"
              className="h-3 md:h-7"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            />
          </a>
          <a
            href="https://www.coingecko.com/en/coins/slav"
            target="_blank"
            rel="noreferrer"
          >
            <motion.img
              src="/images/coingecko.png"
              className="h-4 md:h-9"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            />
          </a>
          <a
            href="https://dexscreener.com/bsc/0xc2d8f743a44b74db656025bbee34949b5e4b0123"
            target="_blank"
            rel="noreferrer"
          >
            <motion.img
              src="/images/dexscreener.png"
              className="h-4 md:h-9"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            />
          </a>
          <a
            href="https://bscscan.com/token/0x5CC3De2a7f03b1C0a5661A846b367460C3BD128F"
            target="_blank"
            rel="noreferrer"
          >
            <motion.img
              src="/images/bscscan.png"
              className="h-4 md:h-9"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            />
          </a>
        </div>
        <div className="relative flex flex-col p-4 mt-16 items-center rounded-full border-4">
          <div className="absolute -top-12">
            <Launch
              wings={false}
              text={
                <div className="flex flex-row gap-2 items-center">
                  <img src="/logo.png" alt="Logo" className="h-10" />
                  <span className="font-bold text-lg">$SLAV</span>
                </div>
              }
            />
          </div>
          <span className="text-white text-sm md:text-lg font-semibold">
            0x5CC3De2a7f03b1C0a5661A846b367460C3BD128F
          </span>
        </div>
      </div>
      <div className="flex flex-col md:flex-row relative md:pb-[119px] md:justify-center items-center pb-10">
        <div className="flex flex-col w-full md:w-1/2 items-center gap-5 pt-[74px] md:pt-[118px] hero-info">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
            className="flex flex-row gap-2 text-3xl md:text-4xl text-center hero-5m"
          >
            5M+ Meme Community Enters WEB3
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.8 }}
            className="flex flex-row gap-4 items-center"
          >
            <img src="/images/mm.png" alt="m" className="h-8" />
            <span className="text-2xl md:text-3xl text-white">
              Exclusive Gopnik Party
            </span>
            <img src="/images/mm.png" alt="m" className="h-8" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1 }}
            className="flex flex-row gap-4 items-center"
          >
            <img src="/images/cap.png" alt="m" className="h-[14px]" />
            <span className="text-2xl md:text-3xl text-white">
              CAPS are Mandatory
            </span>
            <img src="/images/cap.png" alt="m" className="h-[14px]" />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: [1, 1.05, 1] }}
            transition={{ duration: 0.6, delay: 1.2 }}
            className="mt-9"
          >
            <Launch wings={true} text="PancakeSwap by Tuesday, 19th November" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: [1, 1.05, 1] }}
            transition={{ duration: 0.6, delay: 1.3 }}
            className="flex flex-row gap-6 items-center mt-9"
          >
            {/* <a
              href="https://www.instagram.com/slav_coin"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/gradient-ig.png"
                alt="fb"
                className="w-[56px]"
              />
            </a> */}
            <a href="https://t.me/SLAV_Coin" target="_blank" rel="noreferrer">
              <img
                src="/images/gradient-tg.png"
                alt="fb"
                className="w-[56px]"
              />
            </a>
            <a href="https://x.com/SLAV_Coin" target="_blank" rel="noreferrer">
              <img src="/images/gradient-x.png" alt="fb" className="w-[56px]" />
            </a>
          </motion.div>
          {/* <motion.a
            href="https://docsend.com/view/y7yqr448a5jbrx4n"
            target="_blank"
            rel="noreferrer"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1 }}
            className="flex flex-row gap-2 bg-[#2BA500] items-center p-2 border-4 rounded-full "
          >
            <img src="/images/doc.svg" alt="doc" className="w-8" />
            <span className="text-xl md:text-2xl text-white">MemePaper</span>
          </motion.a> */}
        </div>
        <div className="w-full md:w-fit flex items-end justify-end relative md:absolute mt-[95px] md:bottom-0 md:justify-self-center md:self-center">
          <motion.img
            initial={{ opacity: 0, y: 20, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: [1, 1.05, 1] }}
            transition={{ duration: 0.6 }}
            src="/images/slav-image.png"
            alt="slav"
            className="h-[209px] md:h-[364px]"
          />
        </div>
        <div className="h-[188px] md:h-[344px] border rounded-xl overflow-hidden w-[331px] justify-self-center self-center md:w-[611px]">
          <video
            src="/images/pfp.mp4"
            className="w-full"
            muted
            autoPlay
            loop
          ></video>
        </div>
      </div>
      {/* <AnimatePresence>
        {showModal && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{ zIndex: 200 }}
          >
            <div
              className="absolute inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm"
              onClick={closeModal}
            ></div>

            <motion.div
              className="bg-gray-950 bg-opacity-90 border-2 border-yellow-500 p-8 md:p-16 rounded-lg shadow-lg z-10"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-3xl md:text-5xl font-bold text-yellow-500 mb-4">
                Coming Soon
              </h2>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence> */}
    </div>
  );
};

export default Hero;
