import { motion, AnimatePresence } from "framer-motion";
import Launch from "./components/Launch";
import { useState } from "react";

const Hero = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  const closeModal = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setShowModal(false);
    }
  };

  return (
    <div className="flex flex-col hero overflow-hidden">
      <div className="text-center flex flex-col items-center w-full text-white mt-[120px] md:mt-[66px]">
        <motion.div
          className="hero-heading text-5xl md:text-[120px]"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          SLAV Coin
        </motion.div>
        <div className="flex flex-row gap-2 items-center justify-center">
          <motion.div
            className="hero-subheading font-normal text-base md:text-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Deflationary Cryptocurrency
          </motion.div>
          {/* <motion.img
            src="/images/bnb.png"
            className="h-4 md:h-5"
            alt="bnb"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          /> */}
        </div>
        <div className="flex flex-row pt-4 items-center w-full justify-between px-2 md:justify-center md:gap-10">
          <a
            href="https://coinmarketcap.com/currencies/slav/"
            target="_blank"
            rel="noreferrer"
          >
            <motion.img
              src="/images/coinmarketcap-seeklogo.png"
              className="h-4 md:h-9"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            />
          </a>
          <a
            href="https://www.pinksale.finance/solana/launchpad/DS98cf19SJAxhgnxZRi3LgJjXKkrTfbJN5iQ3ggN4RDp"
            target="_blank"
            rel="noreferrer"
          >
            <motion.img
              src="/images/pinksale.png"
              className="h-4 md:h-9"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            />
          </a>
          <a
            href="https://www.coingecko.com/en/coins/slav"
            target="_blank"
            rel="noreferrer"
          >
            <motion.img
              src="/images/coingecko.png"
              className="h-4 md:h-9"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            />
          </a>
          <button onClick={() => handleClick()}>
            <motion.img
              src="/images/dexscreener.png"
              className="h-4 md:h-9"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            />
          </button>
        </div>
        <div className="relative flex flex-col p-4 mt-16 items-center rounded-full border-4">
          <div className="absolute -top-12">
            <Launch
              wings={false}
              text={
                <div className="flex flex-row gap-2 items-center">
                  <img src="/logo.png" alt="Logo" className="h-10" />
                  <span className="font-bold text-lg">$SLAV</span>
                </div>
              }
            />
          </div>
          <span className="text-white text-sm md:text-lg font-semibold">
            XXXXXXXXXXXXXXXXXXXXXXXXX
          </span>
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row gap-[95px]">
        <div className="w-full md:w-fit flex items-end justify-end">
          <motion.img
            initial={{ opacity: 0, y: 20, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: [1, 1.05, 1] }}
            transition={{ duration: 0.6 }}
            src="/images/slav-image.png"
            alt="slav"
            className="h-[354px] md:h-[638px]"
          />
        </div>
        <div className="flex flex-col w-full md:w-1/2 items-center gap-5 pt-[74px] md:pt-[118px] hero-info">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
            className="flex flex-row gap-2 text-3xl md:text-4xl text-center hero-5m"
          >
            5M+ Meme Community Enters WEB3
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.8 }}
            className="flex flex-row gap-4 items-center"
          >
            <img src="/images/mm.png" alt="m" className="h-8" />
            <span className="text-2xl md:text-3xl text-white">
              Exclusive Gopnik Party
            </span>
            <img src="/images/mm.png" alt="m" className="h-8" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1 }}
            className="flex flex-row gap-4 items-center"
          >
            <img src="/images/cap.png" alt="m" className="h-[14px]" />
            <span className="text-2xl md:text-3xl text-white">
              CAPS are Mandatory
            </span>
            <img src="/images/cap.png" alt="m" className="h-[14px]" />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: [1, 1.05, 1] }}
            transition={{ duration: 0.6, delay: 1.2 }}
            className="mt-9"
          >
            <Launch wings={true} text="Public Sale in December" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: [1, 1.05, 1] }}
            transition={{ duration: 0.6, delay: 1.3 }}
            className="flex flex-row gap-6 items-center mt-9"
          >
            {/* <a
              href="https://www.instagram.com/slav_coin"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/gradient-ig.png"
                alt="fb"
                className="w-[56px]"
              />
            </a> */}
            <a href="https://t.me/SLAV_Coin" target="_blank" rel="noreferrer">
              <img
                src="/images/gradient-tg.png"
                alt="fb"
                className="w-[56px]"
              />
            </a>
            <a href="https://x.com/SLAV_Coin" target="_blank" rel="noreferrer">
              <img src="/images/gradient-x.png" alt="fb" className="w-[56px]" />
            </a>
          </motion.div>
          {/* <motion.a
            href="https://docsend.com/view/y7yqr448a5jbrx4n"
            target="_blank"
            rel="noreferrer"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1 }}
            className="flex flex-row gap-2 bg-[#2BA500] items-center p-2 border-4 rounded-full "
          >
            <img src="/images/doc.svg" alt="doc" className="w-8" />
            <span className="text-xl md:text-2xl text-white">MemePaper</span>
          </motion.a> */}
        </div>
      </div>
      <AnimatePresence>
        {showModal && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{ zIndex: 200 }}
          >
            <div
              className="absolute inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm"
              onClick={closeModal}
            ></div>

            <motion.div
              className="bg-gray-950 bg-opacity-90 border-2 border-yellow-500 p-8 md:p-16 rounded-lg shadow-lg z-10"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-3xl md:text-5xl font-bold text-yellow-500 mb-4">
                Coming Soon
              </h2>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Hero;
