import Marquee from "react-fast-marquee";

const SlideText = () => {
  return (
    <Marquee
      autoFill
      className="slide-text flex flex-row gap-6 text-xl md:text-[32px] w-full text-white items-center bg-[#2BA500] py-3"
    >
      <div className="px-2">“$SLAV MEMECOIN”</div>
      <div className="px-2">“$SLAV MEMECOIN”</div>
      <div className="px-2">“$SLAV MEMECOIN”</div>
    </Marquee>
  );
};

export default SlideText;
