import { useRef } from "react";
import { motion, useInView } from "framer-motion";
import { proofs, blogs, videos } from "../constants";

const Showcase = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animationVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, scale: [1, 1.1, 1] },
  };

  return (
    <div className="flex flex-col pt-16 md:pt-28 showcase items-center relative pb-6 md:pb-[180px]">
      <div className="flex flex-row items-center showcase-heading md:gap-8">
        <motion.img
          ref={ref}
          src="/images/graphic-squat-1.png"
          alt="squat"
          className="h-20 md:h-[250px] absolute md:relative top-0 left-6"
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={animationVariants}
          transition={{ duration: 0.4 }}
        />
        <div className="flex flex-col items-center">
          <motion.span
            ref={ref}
            className="text-[32px] md:text-[81px] text-white"
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={animationVariants}
            transition={{ duration: 0.4, delay: 0.2 }}
          >
            Culture, Stereotype or
          </motion.span>
          <motion.span
            ref={ref}
            className="text-[40px] md:text-[89px] text-[#2BA500]"
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={animationVariants}
            transition={{ duration: 0.4, delay: 0.4 }}
          >
            just a MEME?
          </motion.span>
        </div>
        <motion.img
          ref={ref}
          src="/images/graphic-squat-2.png"
          alt="squat"
          className="h-24 md:h-[232px] absolute md:relative right-0 top-44 md:top-0 "
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={animationVariants}
          transition={{ duration: 0.6 }}
        />
      </div>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={animationVariants}
        transition={{ duration: 0.6 }}
        className="flex flex-wrap gap-4 justify-center items-center mt-3 md:mt-[120px]"
      >
        {proofs.map((proof) => (
          <a href={proof.link} key={proof.id}>
            <img src={proof.src} alt="proof" className="w-60 md:w-[401px]" />
          </a>
        ))}
      </motion.div>
      {/* <div className="flex flex-col md:flex-row md:gap-24 gap-5 pt-4 md:pt-16 p-4 md:p-0">
        <div className="flex flex-col gap-4">
          <motion.div
            ref={ref}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={animationVariants}
            transition={{ duration: 0.6 }}
            className="text-3xl md:text-5xl footer-header text-black"
          >
            Watch
          </motion.div>
          <div className="flex flex-col md:flex-row md:gap-24 gap-3">
            <div className="flex flex-col gap-3">
              {videos.slice(0, 2).map((video) => (
                <motion.a
                  href={video.link}
                  target="_blank"
                  rel="noreferrer"
                  ref={ref}
                  key={video.id}
                  animate={isInView ? "visible" : "hidden"}
                  variants={animationVariants}
                  transition={{ duration: 0.6, delay: 0.4 }}
                  className="flex flex-row gap-4 items-center"
                >
                  <img src="/images/youtube.svg" alt="yt" />
                  <span className="footer-header text-lg text-black">
                    {video.title}
                  </span>
                </motion.a>
              ))}
            </div>
            <div className="flex flex-col gap-3">
              {videos.slice(2, 4).map((video) => (
                <motion.a
                  href={video.link}
                  target="_blank"
                  rel="noreferrer"
                  ref={ref}
                  key={video.id}
                  animate={isInView ? "visible" : "hidden"}
                  variants={animationVariants}
                  transition={{ duration: 0.6, delay: 0.4 }}
                  className="flex flex-row gap-4 items-center"
                >
                  <img src="/images/youtube.svg" alt="yt" />
                  <span className="footer-header text-xl text-black">
                    {video.title}
                  </span>
                </motion.a>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <motion.div
            ref={ref}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={animationVariants}
            transition={{ duration: 0.6 }}
            className="text-3xl md:text-5xl footer-header text-black"
          >
            Read
          </motion.div>
          <div className="flex flex-col gap-3">
            {blogs.map((blog) => (
              <motion.a
                href={blog.link}
                target="_blank"
                rel="noreferrer"
                key={blog.id}
                ref={ref}
                animate={isInView ? "visible" : "hidden"}
                variants={animationVariants}
                transition={{ duration: 0.6, delay: 0.4 }}
                className="flex flex-row gap-4 items-center"
              >
                <img src="/images/link.svg" alt="yt" />
                <span className="footer-header text-xl text-black">
                  {blog.title}
                </span>
              </motion.a>
            ))}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Showcase;
