import "./App.css";
import Hero from "./components/Hero";
import SlideText from "./components/SlideText";
import Showcase from "./components/Showcase";
import Tokenomics from "./components/Tokenomics";
import Footer from "./components/Footer";
// import { Advisor } from "./components/Advisor";
import Roadmap from "./components/Roadmap";
// import { MiniAppButton } from "./components/Hero/components/MiniAppButton";
// import { EclipseDomainsButton } from "./components/Hero/components/EclipseDomainsButton";

function App() {
  return (
    <div className="App">
      <Hero />
      {/* <MiniAppButton />
      <EclipseDomainsButton
        click={() => {
          window.open("https://eclipse.slav.meme/");
        }}
      />*/}
      <SlideText />
      <Showcase />
      {/* <Advisor /> */}

      <Tokenomics />
      <Roadmap />
      <Footer />
    </div>
  );
}

export default App;
