export const proofs = [
  {
    id: 1,
    src: "/images/proof-1.png",
    link: "https://www.youtube.com/watch?v=2-8gsWZqDBM",
  },
  {
    id: 2,
    src: "/images/proof-2.png",
    link: "hhttps://www.youtube.com/watch?v=VLW1ieY4Izw",
  },
  {
    id: 3,
    src: "/images/proof-3.png",
    link: "https://www.youtube.com/watch?v=Qif-Qz7NY48",
  },
  {
    id: 4,
    src: "/images/proof-4.png",
    link: "https://www.youtube.com/watch?v=ES8fFBL8mVY",
  },
  {
    id: 5,
    src: "/images/proof-5.png",
    link: "https://www.mirasafety.com/blogs/news/the-history-of-the-slav-squat",
  },
  {
    id: 6,
    src: "/images/proof-6.png",
    link: "https://www.diggitmagazine.com/articles/squatting-slavs-culture-stereotype-or-just-meme",
  },
];

export const videos = [
  {
    id: 7,
    title: "what is gopnik?",
    link: "https://www.youtube.com/watch?v=Qif-Qz7NY48",
  },
  {
    id: 8,
    title: "How to Dance?",
    link: "hhttps://www.youtube.com/watch?v=VLW1ieY4Izw",
  },
  {
    id: 9,
    title: "How to Squat?",
    link: "https://www.youtube.com/watch?v=2-8gsWZqDBM",
  },
  {
    id: 10,
    title: "Russia's Most “HATED” Sub-Culture…",
    link: "https://www.youtube.com/watch?v=ES8fFBL8mVY",
  },
];

export const blogs = [
  {
    id: 11,
    title: "The History of the Slav Squat",
    link: "https://www.mirasafety.com/blogs/news/the-history-of-the-slav-squat",
  },
  {
    id: 12,
    title: "Squatting Slavs: a culture, a stereotype or just a meme?",
    link: "https://www.diggitmagazine.com/articles/squatting-slavs-culture-stereotype-or-just-meme",
  },
  {
    id: 13,
    title:
      "Squatting Slavs: One of the internet’s best-known memes is evolving",
    link: "https://emerging-europe.com/after-hours/squatting-slavs-one-of-the-internets-best-known-memes-is-evolving/",
  },
];

export const advisors = [
  {
    id: 14,
    name: "Saul Pink",
    image: "/images/saul-pink.png",
    position: "Founder and CEO",
    company: "Pinksale.finance",
    description: "The biggest Decentralized Launchpad",
    website: "https://www.pinksale.finance/",
    x: "https://x.com/pinkecosystem",
  },
  {
    id: 15,
    name: "Yann Pink",
    image: "/images/yann-pink.png",
    position: "CBDO",
    company: "Pinksale.finance",
    description: "The biggest Decentralized Launchpad",
    website: "https://www.pinksale.finance/",
    x: "https://twitter.com/Yannpinksale",
  },
];

export const roadmapContent = [
  {
    heading: "🚀 Initial Launch and Utility",
    points: [
      "CG & CMC Listed",
      "Trending Campaigns/Listins on DexView, BirdEye, Dextools, Dexscreener, etc",
      // "Utility: Eclipse Domains, MiniApp (Play, Trade, Bet, Predict)",
    ],
  },
  {
    heading: "🚀 $10M M-Cap",
    points: [
      "Slav memenft giveaway",
      "Airdrop to holders",
      "Buy back & burn",
      "Kick Ass marketing campaigns",
      "Onboard Lots of Kols",
    ],
  },
  {
    heading: "🚀 $20M M-Cap",
    points: [
      "T1 Ex Listing",
      "Live YT Party",
      "Live GiveAways",
      "Merch Giveaways",
      "Onboard tons of youtubers",
      "Growing the Hype and Engaging Community",
    ],
  },
  {
    heading: "🚀 $40M M-Cap",
    points: [
      "More T1 Ex Listings",
      "Giveaway to Brokies",
      "Scaling Visibility and Momentum",
    ],
  },
  {
    heading: "🚀 $60M M-Cap",
    points: [
      "List more and more Ex",
      "Market the shit out of it",
      "Create the biggest movement",
    ],
  },
  {
    heading: "🚀 $80M M-Cap",
    points: [
      "Further Expansion and Incentives",
      "Movement goes with Slav memenfts",
      "Slav to the moon and beyond show",
      "Humor + Value = Community",
    ],
  },
  {
    heading: "🚀 $100M M-Cap",
    points: [
      "Massive Community and Movement Building",
      "What colour of your buggati - campaign",
      "Whose bag is bigger - campaign",
      "Lot's of Utility developmets to buy and burn $Slav",
    ],
  },
];
